import {useState, useEffect} from 'react';
import './App.css';

function App() {
  const [path, setPath] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [answer, setAnswer] = useState(false);
  const [count, setCount] = useState(0);
  const [word, setWord] = useState('');
  const [options, setOptions] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    fetchWord('');
  }, [path]);

  const changePath = newGuess => {
    const newPath = path + newGuess;
    setPath(newPath);
  }

  const fetchWord = async (newGuess) => {
    setIsFetching(true);
    const res = await fetch('https://redactinator.olafmoriarty.com/check.php?path=' + path);
    const json = await res.json();
    if (json.answer) {
      setAnswer(json.answer);
      setCount(0);
    }
    else {
      setCount(json.count);
      setWord(json.nextWord);
      if (json.options && Array.isArray(json.options)) {
        setOptions(json.options);
      }
      if (!path) {
        setAnswer(false);
        setOptions([]);
      }
    }
    setIsFetching(false);
  }

  let dialogueBalloon;
  if (isFetching) {
    dialogueBalloon = <div className="dialogue">
      <p className="thinking">Thinking ...</p>
    </div>
  }
  else if (answer) {
    dialogueBalloon = <div className="dialogue">
      <p>I believe the article you're looking for is</p>
      <p className="answer">{answer}</p>
    </div>
  }
  else {
    dialogueBalloon = <div className="dialogue">
      <p>Does the article contain the word</p>
      <p className="wordline"><span className="word">{word}</span>?</p>
      <div className="buttons">
        <button className="yes" onClick={() => changePath('Y')}>Yes</button>
        <button className="no" onClick={() => changePath('N')}>No</button>
      </div>
      {word.match(/^[0-9]$/) ? <p className="number-note"><strong>Redactle Unlimited users:</strong> If Redactle Unlimited says that the article does contain {word}, but it <strong>only</strong> occurs in ISBN-formatted numbers such as █-█████-███-{word}, you should press "No" here, as that occurence won't count as an occurence of {word} in the original Redactle.</p> : false}
    </div>
  }

  const robotInfo = <div className="info">
    <p><em>Redactinator</em> is a tiny coding project attempting to make a script that can solve <a href="https://redactle.com">Redactle</a> puzzles in as few guesses as possible.</p>
    <p>Redactle and Redactle Unlimited parses words slightly differently, which means that in some cases a word may exist in an article according to Redactle, but not according to Redactle Unlimited, or vice versa. For Redactinator, I've been attempting to copy Redactle's parsing method, which means that for some (hopefully very few) articles,  the Redactinator will not return the correct article when used on Redactle Unlimited.</p>
    <p>Please note that while Redactle (and Redactle Unlimited) fetches an article directly from Wikipedia's API when you attempt to solve it, Redactinator is based on a local copy I downloaded from Wikipedia in late July 2022, so the more time has passed between July 2022 and the time you're reading this, the more errors there will be in Redactinator.</p>
  </div>

  return (
    <div className="App">
      <header>
      <h1>Redactinator</h1>
      <p>The friendly Redactle-solving robot</p>
      </header>

      {dialogueBalloon}
      {(!isFetching && !answer && count > 1) ? <div className="possibles">
        <p className="possible-articles">{count} possible articles</p>
        {options.length ? <ul>{options.map((art, index) => <li key={index}>{art}</li>)}</ul> : false}
      </div> : false}

      <p className="bottom-buttons"><button onClick={() => setPath('')}>Restart</button> <button onClick={() => setShowInfo(showInfo ? false : true)}>About</button></p>
      {showInfo ? robotInfo : false}
    </div>
  );
}

export default App;
